.doctor-div {
   background-color: #354B6B;
   border-radius: 5px;
   width: 90%;
   min-width: 700px;
   margin: 20px;
   padding: 30px;
}

.doctor-card-header {
   color: #FBFAF9;
   display: inline-block;
   margin: 10px;
   font-size: 40px;
   font-family: 'Arimo', sans-serif; }

.address-text {
   color: #FBFAF9;
   display: inline-block;
   font-size: 20px;
   margin: 5px;
   font-family: 'Arimo', sans-serif;
}

.opening-times-header {
   margin: 20px;
   font-size: 30px;
   font-family: 'Arimo', sans-serif;
   color: #FBFAF9;
}

.times-div {
   display: flex;
   flex-direction: row;
   margin: 25px;
}