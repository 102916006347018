.booking-card {
    background-color: #707994;
    width: 80%;
    min-width: 600px;
    margin: 50px;
    padding: 30px;
    border-radius: 5px;
}

.booking-info-text {
    font-size: 20px;
    color: #FBFAF9;
}

#cancelled {
    color: #F44336;
}