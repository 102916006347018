.nav{
    display: flex;
    flex-direction: row;
    background-color: #96C7E0;
}

.nav-option{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #96C7E0;
    margin-right: 20px;
}

.nav-option:hover{
    cursor: pointer;
}

.nav-text {
    color:#FBFAF9;
    font-family: 'Arimo', sans-serif;
}
    