.day-div {
    width: 120px;
    margin: 8px;
    background-color: #707994;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.day {
    color: #FBFAF9;
    font-size: 25px;
}

.times{
    color: #FBFAF9;
    font-size: 15px;
}